import React, { Component } from "react";
import { Carousel, Container } from "react-bootstrap";

import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import "../../css/modal-video.css";
import pattern2 from "../../images/c4-pattern.png";
class C6 extends Component {
  state = {
    isOpen: 0,
    win: false,
  };
  componentDidMount() {
    if (window !== undefined) {
      this.setState({ win: true });
      let items = document.getElementsByClassName("tile");
      items[1].classList.add("tile-hover");
      [...items].forEach((element, i) => {
        element.addEventListener("mouseenter", () => {
          items[i].classList.add("tile-hover");
          items[(i + 1) % 3].classList.remove("tile-hover");
          items[(i + 2) % 3].classList.remove("tile-hover");
        });
        element.addEventListener("mouseleave", () => {
          items[1].classList.add("tile-hover");
          items[2].classList.remove("tile-hover");
          items[0].classList.remove("tile-hover");
        });
      });
    }
  }
  componentDidUpdate() {
    if (window !== undefined) {
      let items = document.getElementsByClassName("tile");
      items[1].classList.add("tile-hover");
      [...items].forEach((element, i) => {
        element.addEventListener("mouseenter", () => {
          items[i].classList.add("tile-hover");
          items[(i + 1) % 3].classList.remove("tile-hover");
          items[(i + 2) % 3].classList.remove("tile-hover");
        });
        element.addEventListener("mouseleave", () => {
          items[1].classList.add("tile-hover");
          items[2].classList.remove("tile-hover");
          items[0].classList.remove("tile-hover");
        });
      });
    }
  }
  render() {
    if (!this.props.data) return <></>;
    return (
      <React.Fragment>
        <img
          alt=""
          className="c6-pattern2"
          src={pattern2}
          width="auto"
          height="504px"
        />
        <img
          alt=""
          className="c6-pattern3"
          src={pattern2}
          width="auto"
          height="504px"
        />
        <Container fluid className="c6 c-pad">
          <div className="c6-green text-center">Featured Video</div>
          <div
            className="c-h1 text-center mb-5"
            style={{ maxWidth: "548px", marginInline: "auto" }}
          >
            {this.props.data.header}
          </div>
          <div className="react-player">
            <Carousel className="d-md-none video-carousel">
              {this.props.data.videos.map((video, index) => (
                <Carousel.Item className="" key={index}>
                  <div
                    key={index}
                    className=""
                    onClick={() => this.setState({ isOpen: 1 })}
                  >
                    <div className="video-overlay"></div>
                    <div className="">
                      <img
                        alt=""
                        className=""
                        src={this.props.assets[video.thumbnail].url}
                      />
                    </div>
                    <div className="">
                      <div className="video-title">{video.title}</div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="contain d-none d-md-block">
              <div className="mv-row">
                <div className="row__inner">
                  {this.props.data.videos.map((video, index) => (
                    <div
                      key={index}
                      className="tile"
                      onClick={() => this.setState({ isOpen: index + 1 })}
                    >
                      <div className="tile__media">
                        <img
                          alt=""
                          className="tile__img"
                          src={this.props.assets[video.thumbnail].url}
                        />
                      </div>
                      <div className="tile__details">
                        <div className="tile__title">{video.title} </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {this.state.win ? (
            <React.Fragment>
              {this.props.data.videos.map((video, index) => (
                <ModalVideo
                  key={index}
                  channel="youtube"
                  isOpen={this.state.isOpen === index + 1}
                  videoId={video.id}
                  onClose={() => this.setState({ isOpen: 0 })}
                  autoplay
                />
              ))}
            </React.Fragment>
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

export default C6;
